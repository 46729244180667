<template>
    <v-container class="px-0 mr-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="DeleteManual"/>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-data-table
        :search="nameSearchable"
        v-if="!api.isLoading && data!=null"
        :items="data"
        :headers="dataHeaders">
            <template v-slot:top class="pa-3">
                <v-toolbar width="auto" height="auto" color="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <!-- <v-icon large color="black" class="px-2">
                                    fa-user-circle
                                </v-icon> -->
                                <v-toolbar-title class="font-weight-bold">
                                    Manual
                                </v-toolbar-title>
                            </v-row>
                            <v-row class="mt-6">
                                <v-text-field
                                dense
                                outlined
                                clearable
                                v-model="nameSearchable"
                                label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                        <v-btn class="mx" color="primary" v-if="currentRole=='operation'" @click="redirectToManualCreate">
                            New
                        </v-btn>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{item}">
                <v-btn color="primary" @click="redirectToManualUpdate(item.id)" plain>Edit</v-btn>
                <v-btn color="red" @click="confirmDelete(item.id)" plain>Delete</v-btn>
            </template>
            <template v-slot:item.name="{item}">
                <a @click="redirectToManualDetail(item.key)" class="text-decoration-none">
                    {{ item.name }}
                </a>
            </template>

        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    currentRole:null,
    nameSearchable:null,
    data:null,
    DeletedId:null,
    isPending:false,
    dataHeaders:[
        {
            text:'Name',
            value:'name',
        },
        {
            text:'Action',
            value:'action',
        },
    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getListofManual"){
                this.data=resp.data;
            }
            if(resp.class=="deleteManual"){
                location.reload();
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    this.fetch();
    },
    methods: {
        fetch(){
            this.currentRole = this.$store.getters.getRole; 
            let fetchManualsApi = this.fetchManuals();
            this.$axios.all([
                this.$api.fetch(fetchManualsApi),
            ]);

        },
        fetchManuals(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/manual';
            return tempApi;
        },
        redirectToManualDetail(id){
            this.$router.push({name:'PageManualDetail',params:{'id':id}})
        },
        redirectToManualUpdate(id){
            this.$router.push({name:'PageManualUpdate',params:{'id':id}});  
        },
        redirectToManualCreate(){
            this.$router.push({name:'PageManualCreate'});
        },
        confirmDelete(id){
            this.isPending = true;
            this.DeletedId = id;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        DeleteManual(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/manual/"+this.DeletedId;
            this.$api.fetch(tempApi);
        }
    }
}
</script>